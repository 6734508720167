

// eslint-disable-next-line
// @ts-ignore
// import svgJS from "@/plugins/svg.js";
// eslint-disable-next-line
// import { SVG } from "@svgdotjs/svg.js";

// https://codesandbox.io/s/m324p7lpoy?file=/src/plugins/svg.js


// DAN POTTS DOES:
// https://danpottsdoes.wordpress.com/2018/09/17/how-to-type-vue-js-refs-refs-typescript/

// https://stackoverflow.com/questions/46505813/vuejs-typescript-this-refs-reffield-value-does-not-exist

// NOTE: these are mostly vue-2
// vue class component https://class-component.vuejs.org/
// https://github.com/kaorun343/vue-property-decorator
// TUTORIAL FROM VUE2 to VUE3
//      * https://v3.vuejs.org/guide/typescript-support.html#editor-support
//      * https://labs.thisdot.co/blog/your-first-vue-3-app-using-typescript


import { defineComponent } from 'vue';  // ?? wtf

interface State {
    nextShroom: string;         /* which shroom is being shown */ 
    babyShroomDisplay: string; 
    teenShroomDisplay: string;
    daddyShroomDisplay: string;
    timePassed: number; 
    timerInterval: number;
}

 
/*
The code makes use of the new defineComponent global method. This method lets TypeScript properly infer types inside of the Vue Component options.
The defineComponent method accepts an input parameter object. It can be an Options API object or a Composition API object.

good defineComponent docs:
https://www.vuemastery.com/blog/getting-started-with-typescript-and-vuejs/

*/
export default defineComponent({
    name: 'FFLogo',

    data: (): State => {
        return {
            nextShroom: 'baby',
            babyShroomDisplay: 'inline',
            teenShroomDisplay: 'inline',
            daddyShroomDisplay: 'inline',
            timePassed: 0, 
            timerInterval: 3
         }
    }, 

    mounted() {
       this.startTimer();
    }, 

    methods: {
        shroomIn(shroomType: string) {
            switch(shroomType) {
                case 'baby':
                    this.babyShroomDisplay = 'block'
                    this.teenShroomDisplay = 'none'
                    this.daddyShroomDisplay = 'none'
                    this.nextShroom = 'teen'
                    break;
                case 'teen':
                    this.babyShroomDisplay = 'none'
                    this.teenShroomDisplay = 'block'
                    this.daddyShroomDisplay = 'none'
                    this.nextShroom = 'daddy'
                    break;
                case 'daddy':
                    this.babyShroomDisplay = 'none'
                    this.teenShroomDisplay = 'none'
                    this.daddyShroomDisplay = 'block'
                    this.nextShroom = 'none'
                    break;
                default:
                    this.babyShroomDisplay = 'none'
                    this.teenShroomDisplay = 'none'
                    this.daddyShroomDisplay = 'none'
                    this.nextShroom = 'baby'
                    break;
            }      
        },
        startTimer() {
            this.timerInterval = setInterval(() => {
                (this.timePassed += 1)
                this.shroomIn(this.nextShroom)
                }
                , 1000);
        }, 
        fun() {    
            this.babyShroomDisplay = 'inline'
            this.teenShroomDisplay = 'inline'
            this.daddyShroomDisplay = 'inline'
            this.nextShroom = 'none';
        }
    }, 

  
})

