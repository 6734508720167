

import { defineComponent } from 'vue';  // ?? wtf

import { Icon } from '@iconify/vue';
// import home from '@iconify-icons/mdi/home';
import iconTwitter from '@iconify-icons/brandico/twitter-bird';
import iconLinkedIn from '@iconify-icons/brandico/linkedin-rect';
import iconSkype from '@iconify-icons/brandico/skype';
import iconEmail from '@iconify-icons/mdi/email';
import iconPhone from '@iconify-icons/mdi/phone-classic';
// import iconMap from '@iconify-icons/mdi/map-marker-radius';

interface State {
    icons: any;
}

export default defineComponent({
    name: 'ContactFF',
    components: {
        Icon
    }, 
    data: (): State => {
        return {
            icons: {
                twitter: iconTwitter,
                linkedin: iconLinkedIn,
                email: iconEmail,
                phone: iconPhone,
                skype: iconSkype
                }
            }
        },
    // mounted() {},
    methods: {

    }

})


