
import { Options, Vue } from 'vue-class-component';
import ContactFF from '@/components/ContactFF.vue';
import FFLogo from '@/components/FFLogo.vue';
// import Counter from '@/components/Counter.vue';
// import nput from '@/components/nput.vue';

/*
  Tailwind
  https://medium.com/@FlorianWoelki/vue-3-and-tailwindcss-2041fea3bcd

*/

@Options({
  components: {
    ContactFF,
    FFLogo
  },
})
export default class Home extends Vue {
    data() {
      return {
        }
      }

}
